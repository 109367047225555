<template>
  <page-content :show-search="is_admin">
    <template #search>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="false"
        :search-tips="$t('device_reset.search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />

    </template>

  </page-content>
</template>

<script>
import common from "@/common";
import TableContent from "@/components/TableContent";
import TableHeader from "@/components/TableHeader";
import TableSearch from "@/components/TableSearch";
import PageContent from "@/components/PageContent";
import ModalForm from "@/components/ModalForm";

export default {
  name: 'bedexitreset',
  components: {
    ModalForm,
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('bedexitreset'),
      is_admin: common.isAdmin(),
      view_permission: common.checkPermission('Mon_Menu_Reset'),
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'common.floor',
          field: 'floorName',
          width: '110px',
        },
        {
          label: 'common.room',
          field: 'roomName',
          width: '110px',
        },
        {
          label: 'common.bed',
          field: 'bedNumber',
          width: '110px',
        },
        {
          label: 'device_reset.mac',
          field: 'deviceMAC',
          width: '200px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value}</span>`
        },
        {
          label: 'device_reset.status',
          type: 'select',
          field: 'isOnBed',
          sortable: false,
          style: 'width: 150px',
          width: '180px',
          options: [
            {text: common.getI18n('device_reset.status_preset'), value: ''},
            {text: common.getI18n('device_reset.status_on'), value: true},
            {text: common.getI18n('device_reset.status_exit'), value: false},
          ],
          selectedFun: 'statusSelected'
        },
        // {
        //   label: 'common.action',
        //   field: 'action',
        //   width: '200px',
        //   sortable: false,
        // },
      ],
      header_actions: [
        // {
        //   text: 'common.add',
        //   variant: 'primary',
        //   fun: this.addData,
        //   permission: ''
        // },
      ],
      table_actions: [

      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },


      ],
      filterValue: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
      },
    }
  },
  activated() {
    common.getSearchCompanyOptions('companyID', this)
    common.getSearchFacilityOptions('facilityID', this.filterValue.companyID, this)
    common.getSearchFloorOptions('floorID', this.filterValue.facilityID, this)
    this.getList()
  },
  deactivated() {
    this.$refs.search.resetFilterValueToFiltered()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const api = '/bemonitor/GetForceSettingMonitorList'
      const search = this.getSearchCondition()

      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url).then(res => {
        this.total_rows = res.data.total

        let monitors = res.data.monitors
        for (const i in monitors) {
          if (monitors[i].isOnBed == null) {
            monitors[i].isOnBed = ''
          }
        }

        this.rows = JSON.parse(JSON.stringify(monitors))
      })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    statusSelected: function (data) {
      let post = []
      for (const i in this.rows) {
        if (this.rows[i].monitorID === data.row.monitorID) {
          post.push({facilityID: data.row.facilityID, monitorID: data.row.monitorID, isOnBed: data.option.value})
          this.rows[i].isOnBed = data.option.value
          break
        }
      }

      common.apiPostData('/bemonitor/MonitorForceSetting', post).then(res => {
        this.getList()
      })

    }
  }
}
</script>
